.skills-container{
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
    justify-content: flex-start;
    align-items: center;
}

.skills-heading{
    font-size: 2.5rem;
    font-weight: 600;
    color: var(--primary-color);
    font-family: 'Montserrat', sans-serif;
}

.all-skills-container{
    height: auto;
    width: 75%;
    display: flex;
    flex-direction: row;
    gap: 5;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
}

.frameworks-div{
    height: auto;
    width: 50%;
    padding: 1%;
    display: flex;
    flex-direction: column;
    gap: 7px;
    justify-content: flex-start;
    align-items: flex-start;
    /* background-color: var(--bg-color-2); */
    border-radius: 5px 5px 5px 5px;
}
.skills-subheading{
    font-size: 1.5rem;
    font-family: 'Montserrat',sans-serif;
    font-weight: 600;
    text-align: left;
    color: var(--text-color);
    margin: 0;
    padding: 0;
}
.skills-area{
    height: auto;
    width: 100%;
    display: flex;
    gap: 10px;
    justify-content: flex-start;
    align-items: center;
}
.skill-tile{
    height: 150px;
    width: 150px;
    padding: 2%;
    border-radius: 5px 5px 5px 5px;
    background-color: var(--bg-color-2);
    display: flex;
    gap: 15px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.skill-tile img{
    height: 75%;
    width: 75%;
    aspect-ratio: 4/5;
    object-fit: contain;
}
.skill-tile p{
    font-size: 0.9rem;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    color: var(--text-color);
    text-align: center;
}