.homepage-container{
    min-height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--bg-color);
    color: var(--text-color);
}

.homepage-banner-area{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.middle-div{
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
}

.homepage-banner-texts{
    height: 100%;
    width: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
}
.left-side{
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
}
.left-side-heading{
    font-size: 5rem;
    font-weight: 600;
    color: var(--primary-color);
    text-align: left;
    margin: 0;
    padding: 0;
    font-family: 'Montserrat', sans-serif;
}
.left-side-normal-txt{
    font-size: 1.5rem;
    font-weight: 400;
    color: var(--text-color);
    text-align: left;
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
}
.empty-middle-side{
    height: 75vh;
    /* background-color: mediumblue; */
    width: 50vh;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
}
.empty-middle-side p{
    font-size: 5rem;
    visibility: hidden;
    margin: 0;
    padding: 0;
}
.right-side{
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
.right-side-heading{
    font-size: 5rem;
    font-weight: 600;
    color: var(--primary-color);
    text-align: left;
    margin: 0;
    padding: 0;
    font-family: 'Montserrat', sans-serif;
}
.right-side-normal-txt{
    font-size: 1.5rem;
    font-weight: 400;
    color: var(--text-color);
    text-align: left;
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
}
/* Contents of middle div here */
.portfolio-image-container{
    height: 95vh;
    width: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.portfolio-image-container img{
    height: 100%;
    width: 100%;
    aspect-ratio: 4/5;
    object-fit: cover;
}

.socials-container{
    height: auto;
    width: 100%;
    display: flex;
    gap: 5px;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}
.social-div{
    height: 50px;
    width: 50px;
    border-radius: 50px;
    border: solid var(--primary-color);
    border-width: 2.5px;
    padding: 2%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: var(--primary-color); */
}
.social-div img{
    height: 100%;
    width: 100%;
    aspect-ratio: 3/4;
    object-fit: contain;
}

.social-div:hover{
    background-color: var(--primary-color);
    cursor: pointer;
}
/* Contents of middle div end here */