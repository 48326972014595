.education-page-container{
    min-height: 100vh;
    width: 100%;
    background-color: var(--bg-color);
    display: flex;
    gap: 15px;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.uofg-container{
    height: auto;
    width: 50%;
    padding: 2%;
    display: flex;
    gap: 20px;
    justify-content: flex-start;
    align-items: center;
    border-radius: 10px 10px 10px 10px;
    background-color: var(--bg-color-2);
    cursor: pointer;
}

.muj-container{
    height: auto;
    width: 50%;
    padding: 2%;
    display: flex;
    gap: 20px;
    justify-content: flex-start;
    align-items: center;
    border-radius: 10px 10px 10px 10px;
    background-color: var(--bg-color-2);
    cursor: pointer;
}

.uofg-logo-container{
    height: 30vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.uofg-logo-container img{
    height: 100%;
    width: 100%;
    aspect-ratio: 2/3;
    object-fit: contain;
}
.muj-logo-container{
    height: 30vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.muj-logo-container img{
    height: 100%;
    width: 100%;
    aspect-ratio: 2/3;
    object-fit: contain;
}
.uofg-qualifications{
    /* height: 100%; */
    flex: 2;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: flex-start;
    align-items: flex-start;
}
.muj-qualifications{
    /* height: 100%; */
    flex: 2;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: flex-start;
    align-items: flex-start;

}
.education-main-heading{
    font-size: 2.5rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    color: var(--primary-color);
    margin: 0;
    padding: 0;
    text-align: left;
}
.education-heading{
    font-size: 1.5rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    color: var(--primary-color);
    margin: 0;
    padding: 0;
    text-align: left;
}
.education-subheading{
    font-size: 1.25rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    color: var(--text-color);
    margin: 0;
    padding: 0;
    text-align: left;
}
.education-texts{
    font-size: 1rem;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    color: var(--text-color);
    margin: 0;
    padding: 0;
    text-align: left;
}