.portfolio-navbar{
    height: auto;
    width: 100%;
    padding: 1%;
    display: flex;
    justify-content:space-evenly;
    align-items: center;
    background-color: var(--bg-color);
    color: var(--text-color);
    z-index: 99999999;
}

.portfolio-navbar.nav-link{
    text-decoration: none;
    font-family: 'Montserrat', sans-serif;
    color: var(--text-color);
    font-size: 1rem;
    font-weight: 400;
    transition: 0.3s font-weight ease-in-out;
}
.nav-link:hover{
    font-weight: 500;
    color: var(--primary-color);
    cursor: pointer;
}