.projects-list-container{
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;
}

.project-list-heading{
    font-size: 2.5rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    text-align: left;
    color: var(--primary-color);
}

.project-tile{
    height: auto;
    width: 50%;
    display: flex;
    gap: 15px;
    justify-content: flex-start;
    align-items: center;
    padding: 1%;
    background-color: var(--bg-color-2);
    border-radius: 5px 5px 5px 5px;
}
.project-icon-container{
    height: auto;
    width: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.project-icon-container img{
    height: 100%;
    width: 100%;
    aspect-ratio: 3/4;
    object-fit: contain;
}

.project-details{
    height: auto;
    width: 100%;
    display: flex;
    gap: 15px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
.project-tile-heading{
    font-size: 1.5rem;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
    color: var(--primary-color);
    text-align: left;
}
.project-tile-about{
    font-size: 0.9rem;
    font-weight: 500;
    color: var(--text-color);
    line-height: 24px;
    text-align: left;
}

.project-stack-text{
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: space-evenly;
    align-items: center;
}

.project-tile-key{
    font-size: 0.9rem;
    font-weight: 500;
    font-family: 'Roboto', sans-serif;
    color: var(--primary-color);
}