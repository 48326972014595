.contact-me-container{
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
}

.contact-heading{
    font-size: 2.5rem;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
    color: var(--primary-color);
    text-align: left;
}

.contact-form-container{
    height: auto;
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 15px;
    justify-content: flex-start;
    align-items: flex-start;
}

.contact-input-container{
    height: auto;
    width: 100%;
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
}

.contact-input{
    flex: 1;
    height: 45px;
    padding: 1%;
    border-radius: 5px 5px 5px 5px;
    border: none;
    outline: none;
    background-color: var(--bg-color-2);
    color: var(--text-color);
}
.contact-input::placeholder{
    color: var(--secondary-text-color);
    font-family: 'Montserrat', sans-serif;
}

.contact-text-area{
    width: 100%;
    padding: 1%;
    border-radius: 5px 5px 5px 5px;
    border: none;
    outline: none;
    background-color: var(--bg-color-2);
    color: var(--text-color);
    font-family: 'Roboto', sans-serif;
    line-height: 24px;
    overflow: hidden;
    resize: none;
}
.contact-text-area::placeholder{
    color: var(--secondary-text-color);
    font-family: 'Montserrat', sans-serif;
}

.contact-action-container{
    margin-top: 10px;
    height: auto;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contact-alert {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 9999;
    width: 300px; /* Adjust as needed */
}