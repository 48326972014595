.full-view-page{
    min-height: 100vh;
    width: 100%;
    display: flex;
    gap: 35px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

#homepage{
    height: 100%;
    width: 100%;
}

#education{
    height: 100%;
    width: 100%;
}
#skills{
    height: 100%;
    width: 100%;
}
#projects{
    height: 100%;
    width: 100%;
}
#contact_me{
    height: 100%;
    width: 100%;
}


